import React from "react";
import { Link } from "gatsby";
import MarkdownRenderer from "react-markdown-renderer";

const Footer = ({ data, menu }) => {
  return (
    <footer>
      <div className="main-container">
        <div className="footer-wrapper">
          <div className="footer-group">
            <div className="footer-info">
              <div className="box address">
                <img className="lazyload" data-src={require("../images/logo.svg")} alt="Logotype" />
                <MarkdownRenderer markdown={data.address} />
              </div>
              <div className="box certificate">
                <h4>{data.certificate}</h4>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://wizytowka.rzetelnafirma.pl/P4O09ESV"
                >
                  <img
                    className="lazyload" data-src={require("../images/icons/rzetelna-firma.svg")}
                    alt="rzetelna firma"
                  />
                </a>
              </div>
            </div>
            <p className="light">{data.footer_info}</p>
            <p className="light copyright-mobile">{data.copyrights}</p>
          </div>
          <div className="footer-group">
            <div className="footer-box">
              <h4>Menu</h4>
              <ul className="footer-links">
                {Object.keys(menu).map((item, index) => (
                  <li key={index}>
                    <Link to={menu[item].link} className="link">
                      {menu[item].label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="footer-box">
              <h4>{data.write_to_us}</h4>
              <a href={`mailto:${data.main_mail}`} className="footer-link link">
                <img className="lazyload" data-src={require("../images/icons/email.svg")} alt="Mail" />
                <span>{data.main_mail}</span>
              </a>
              <h4>{data.or_call}</h4>
              <a href={`tel:+${data.main_tel}`} className="footer-link link">
                <img className="lazyload" data-src={require("../images/icons/phone.svg")} alt="Phone" />
                <span>{data.main_tel}</span>
              </a>
              <div className="row-group">
                <h4>{data.social_media}</h4>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={data.linkedin}
                  className="footer-link--icon"
                >
                  <img
                    className="lazyload" data-src={require("../images/icons/linkedin.svg")}
                    alt="Linkedin"
                  />
                </a>
              </div>
              <p className="light copyright-desktop">{data.copyrights}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
