import React, { Component } from "react";
import { Button } from "./styleguide";
import { Link } from "gatsby";

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isMenuOpen: false });
    }
  }
  render() {
    const { isMenuOpen } = this.state;
    const { data, staticData } = this.props;
    return (
      <>
        <Button
          className="btn-menu btn-menu--opener"
          content={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29.516"
              height="19.798"
              viewBox="0 0 29.516 19.798"
            >
              <g transform="translate(-25.42 -30.117)">
                <path
                  d="M-2439.987,1678.1h-20.437"
                  transform="translate(2490.383 -1646.98)"
                  strokeWidth="2"
                />
                <path
                  d="M-2439.987,1678.1h-20.437"
                  transform="translate(2490.383 -1629.182)"
                  strokeWidth="2"
                />
                <path
                  d="M-2430.908,1678.231h-29.516"
                  transform="translate(2485.844 -1638.206)"
                  strokeWidth="2"
                />
              </g>
            </svg>
          }
          type="button"
          rotated
          size="lg"
          onClick={() => this.setState({ isMenuOpen: true })}
        />
        <nav
          className={`panel--outer ${isMenuOpen ? "open" : ""}`}
          ref={this.setWrapperRef}
        >
          <div className="panel--inner">
            <div className="panel-header">
              <Button
                onClick={() => this.setState({ isMenuOpen: false })}
                className="btn-menu"
                content={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.661"
                    height="20.661"
                    viewBox="0 0 20.661 20.661"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect width="20.661" height="20.661" fill="none" />
                      </clipPath>
                    </defs>
                    <g transform="translate(0 0)" clipPath="url(#clip-path)">
                      <g transform="translate(-1239.493 -63.219)">
                        <path
                          d="M27.219,0H0"
                          transform="translate(1240.2 83.173) rotate(-45)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="2"
                        />
                        <path
                          d="M27.219,0H0"
                          transform="translate(1240.2 63.926) rotate(45)"
                          fill="none"
                          stroke="#fff"
                          strokeWidth="2"
                        />
                      </g>
                    </g>
                  </svg>
                }
                type="button"
                rotated
                size="lg"
              />
            </div>
            <div className="panel-body">
              <ul className="panel-menu">
                {data &&
                  Object.keys(data).map(item => (
                    <li key={item}>
                      <Link to={data[item].link} className="font-ms">
                        {data[item].label}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="panel-footer">
              <ul className="footer-contact">
                <li className="footer-contact--item">
                  <a className="link" href={`mailto:${staticData.main_mail}`}>
                    {staticData.main_mail}
                  </a>
                  <img className="lazyload" data-src={require("../images/icons/email.svg")} alt="email" />
                </li>
                <li className="footer-contact--item">
                  <a className="link" href={`mailto:${staticData.main_tel}`}>
                    {staticData.main_tel}
                  </a>
                  <img className="lazyload" data-src={require("../images/icons/phone.svg")} alt="phone" />
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

export default Nav;
