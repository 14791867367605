import React from "react";
import { Link } from "gatsby";

const Breadcrumbs = ({ data, lang }) => {
  return (
    <ul itemScope itemType="https://schema.org/BreadcrumbList" className="breadcrumbs-list">
      <li itemScope itemType="https://schema.org/ListItem" itemProp="itemListElement" className="breadcrumb-item">
        <Link className="font-ms link" itemProp="item" to={`/${lang || ""}`}>
          <span itemProp="name">{lang === "en" ? "Home" : "Strona główna"}</span>
        </Link>
        <meta itemProp="position" content="1" />
      </li>
      {data &&
        data.map(({ label, href }, index) => (
          <li
            itemScope
            itemType="https://schema.org/ListItem"
            itemProp="itemListElement"
            className="breadcrumb-item"
            key={label}
          >
            <Link className="font-ms link" itemProp="item" to={`${href}`}>
              <span itemProp="name">{label}</span>
            </Link>
            <meta itemProp="position" content={index + 2} />
          </li>
        ))}
    </ul>
  );
};

export default Breadcrumbs;
