import React, { Component } from "react";
import Helmet from "react-helmet";
import { CookiesProvider, withCookies } from "react-cookie";
import Header from "./header";
import Footer from "./footer";

import "../styles/theme.scss";

class Layout extends Component {
  render() {
    const { children, title, data, hrefLang, description } = this.props;
    return (
      <CookiesProvider>
        <Helmet title={title}>
          <meta name='description' content={description} />
          <meta name='google-site-verification' content='sq1gwYwRbQKLAMRM5gaTfatXDxLZBs_L_Ke1SwctxAg' />
        </Helmet>
        <div className='bg-container'>
          <div className='bg-radial' />
          <div className='bg-grid' />
          <div className='main-content'>
            {data && <Header lang={data.lang} hrefLang={hrefLang} staticData={data.static_data[0]} data={data.menuData} />}
            <main>{children}</main>
            {data && <Footer menu={data.footerData} data={{ ...data.static_data[0], ...data.translations[0] }} />}
            <div className='bg-bottom dark' />
            <div className='bg-bottom light' />
          </div>
        </div>
      </CookiesProvider>
    );
  }
}

export default withCookies(Layout);
