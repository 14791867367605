import React from "react";
import { Link } from "gatsby";
import { Button } from "..";
import MarkdownRenderer from "react-markdown-renderer";
import { createSlug } from "../../../../utils/constants.js";

const Post = ({
  data,
  blogPath,
  data: { link, listing_image, title, dateField },
  className,
  primary = false,
}) => {
  return (
    <Link to={link} className={`grid-element single-post ${className || ""}`}>
      <div
        className={`lazyload post-image`}
        style={{
          backgroundImage: `url('${
            listing_image || require("../../../images/blog/post-template.png")
          }')`,
        }}>
        <div className="overlay" />
        <Button type="arrow-button" className="btn-post" />
      </div>
      <div className="post-content">
        <div className="overlay" />
        {dateField && (
          <p className="date font-ms">
            {dateField.split("-").reverse().join(".")}{" "}
          </p>
        )}
        {primary ? (
          <h2>
            <MarkdownRenderer markdown={title} />
          </h2>
        ) : (
          <h3>
            <MarkdownRenderer markdown={title} />
          </h3>
        )}

        <div className="tags-list">
          {data.tag_post_relation &&
            data.tag_post_relation.length > 0 &&
            data.tag_post_relation.map(({ name, id }, index) => (
              <Link
                key={id}
                className="tag"
                to={`${blogPath}/${createSlug(name)}`}>
                #{name}
                {index !== data.tag_post_relation.length - 1 ? "," : ""}
              </Link>
            ))}
        </div>
      </div>
    </Link>
  );
};

export default Post;
