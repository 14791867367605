const slugify = require("slugify");

const createSlug = name =>
  slugify(name, {
    replacement: "-",
    remove: /[#,*+~.()'"?!:@]/g,
    lower: true
  });

const searchToObject = element => {
  var pairs = element.substring(1).split("&"),
    obj = {},
    pair,
    i;
  for (i in pairs) {
    if (pairs[i] === "") continue;
    pair = pairs[i].split("=");
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }

  return obj;
};

const createQuery = (lang, data) =>
  `
  query{
    fringetree {
      ${lang.map(
        lang =>
          `${lang}{${data.map(
            ({ name, identifier, settings }) => `
              ${name}: schemaInstances(identifier: "${identifier}", ${settings}) {
                edges {
                  node {
                    name
                    identifier
                    hreflangSlugs
                    instanceFields {
                      name
                      identifier
                      type
                      content
                    }
                    referencedFields {
                      instanceFields {
                        name
                        identifier
                        content
                      }
                    }
                  }
                }
              }`
          )}
        }`
      )}
    }
  }
  `;

module.exports = {
  createSlug,
  createQuery,
  searchToObject
};
