import React, { Component } from "react";
import { Link } from "gatsby";
import Nav from "./nav";
import { Button } from "./styleguide";

class Header extends Component {
  state = {
    langOpened: false
  };
  handleLangClick = () => {
    this.setState(prevState => {
      return { langOpened: !prevState.langOpened };
    });
  };
  render() {
    const { langOpened } = this.state;
    const { lang, data, staticData, hrefLang } = this.props;
    return (
      <div className="top-header">
        <div className="lg-container">
          <div className="header-wrapper">
            <div className="logo-holder">
              <Link to={`${lang}`}>
                <img
                  className="lazyload"
                  data-src={require("../images/logo.svg")}
                  alt="Logotype"
                />
              </Link>
            </div>
            <div className="header-action">
              <ul className="header-menu">
                {data &&
                  Object.keys(data).map(
                    item =>
                      (item === "products" ||
                        item === "contact" ||
                        item === "blog") && (
                        <li key={item}>
                          <Link to={data[item].link} className="font-ms">
                            {data[item].label}
                          </Link>
                        </li>
                      )
                  )}
              </ul>
              {hrefLang && hrefLang.length > 1 && (
                <div className="hreflangs-container">
                  {hrefLang
                    .filter(item => item.lang === lang)
                    .map(({ lang, slug }) => (
                      <Button
                        key={lang}
                        content={lang}
                        onClick={this.handleLangClick}
                        type="button"
                        rotated
                        size="md"
                      />
                    ))}
                  <div
                    className={`hreflangs-container--hidden ${
                      langOpened ? "open" : ""
                    }`}
                  >
                    {hrefLang
                      .filter(item => item.lang !== lang)
                      .map(({ lang, slug }) => (
                        <Button
                          key={lang}
                          content={lang}
                          type="link"
                          href={`${lang}/${slug}`}
                          rotated
                          size="md"
                        />
                      ))}
                  </div>
                </div>
              )}
              <Nav data={data} staticData={staticData} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
