import React from "react";

const Input = ({
  label,
  id,
  type,
  name,
  placeholder,
  error,
  defalutValue,
  succes,
  onBlur,
  onFocus,
  onChange,
  required,
  value,
  postfix
}) => {
  return (
    <div
      className={`inp-group ${error ? "error" : ""} ${succes ? "succes" : ""}`}
    >
      {label && <label htmlFor={id}>{label}</label>}
      {postfix ? (
        <div className="postfix-wrapper">
          <input
            type={type}
            onChange={onChange ? onChange : null}
            onBlur={({ currentTarget }) =>
              onBlur ? onBlur(currentTarget) : false
            }
            onFocus={onFocus ? onFocus : null}
            name={name}
            id={id}
            placeholder={placeholder}
            required={required ? true : false}
            value={value || ""}
          />
          <span className="postfix">{postfix}</span>
        </div>
      ) : (
        <input
          type={type}
          onChange={onChange ? onChange : null}
          onBlur={({ currentTarget }) =>
            onBlur ? onBlur(currentTarget) : false
          }
          onFocus={onFocus ? onFocus : null}
          name={name}
          id={id}
          placeholder={placeholder}
          required={required ? true : false}
          defaultValue={value || ""}
        />
      )}
    </div>
  );
};

export default Input;
