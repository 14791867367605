import React from "react";
import { Link } from "gatsby";

const Arrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.962"
      height="10.51"
      viewBox="0 0 10.962 10.51"
    >
      <g transform="translate(-508.803 -1390.419)">
        <path
          d="M-3150.2,3017.126l4.548,4.548-4.548,4.548"
          transform="translate(3664 -1626)"
          fill="none"
          strokeWidth="2"
        />
        <path
          d="M-3162.2,3021.674h7.1"
          transform="translate(3672 -1626)"
          fill="none"
          strokeLinecap="square"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

const Button = ({
  type,
  href,
  content,
  className,
  size,
  fill,
  gray,
  rotated,
  onClick
}) => {
  switch (type) {
    case "link":
      return (
        <Link
          onClick={() => onClick && onClick()}
          className={`btn font-ms ${size ? `size-${size}` : ""} ${
            gray ? "gray" : ""
          } ${rotated ? "rotated" : ""}
           ${fill ? "fill" : ""} ${className ? className : ""}`}
          to={href}
        >
          {content}
        </Link>
      );
    case "arrow":
      return (
        <Link
          onClick={() => onClick && onClick()}
          className={`btn font-ms size-md rotated ${
            className ? className : ""
          }`}
          to={href}
        >
          <Arrow />
        </Link>
      );
    case "arrow-button":
      return (
        <button
          onClick={() => onClick && onClick()}
          className={`btn font-ms size-md rotated ${
            className ? className : ""
          }`}
          to={href}
        >
          <Arrow />
        </button>
      );
    default:
      return (
        <button
          onClick={() => onClick && onClick()}
          className={`btn font-ms ${size ? `size-${size}` : ""} ${
            rotated ? "rotated" : ""
          }
          ${gray ? "gray" : ""} 
           ${fill ? "fill" : ""} ${className ? className : ""}`}
          type={type}
        >
          {content}
        </button>
      );
  }
};

export default Button;
