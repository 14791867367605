import React from "react";

const Textarea = ({ label, id, type, name, placeholder, onChange }) => {
  return (
    <div className="textarea-group">
      {label && <label htmlFor={id}>{label}</label>}
      <textarea
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default Textarea;
