import React, { Component } from "react";
import { Link } from "gatsby";
import { Button } from "..";

class Product extends Component {
  render() {
    const {
      data: { name, subtitle, listing_image, href },
      className,
      onClick,
      home = false,
      productPage = false
    } = this.props;
    return (
      <Link
        to={href || "/"}
        onClick={onClick ? onClick : null}
        className={`grid-element single-product ${className || ""}`}
      >
        <div
          className="image-holder"
          style={{
            backgroundImage: `url("${listing_image ||
              require("../../../images/grid/product2.png")}")`
          }}
        >
          <div className="overlay" />
          <Button type="arrow-button" href={href} className="btn-product" />
        </div>
        <div className="text-holder">
          <div className="overlay" />
          {home ? (
            <p className="title">{name}</p>
          ) : productPage ? (
            <h2 className="title">{name}</h2>
          ) : (
            <h3 className="title">{name}</h3>
          )}
          <p>{subtitle}</p>
        </div>
      </Link>
    );
  }
}

export default Product;
