import React from "react";

const Checkbox = ({ id, label, onChange, checked, form, succes, error }) => {
  return (
    <div
      className={`check-group ${form ? "form-check" : ""} ${
        succes ? "succes" : ""
      } ${error ? "error" : ""}`}
    >
      <input
        type="checkbox"
        id={id}
        checked={checked || false}
        onChange={(e) => (onChange ? onChange(e) : false)}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Checkbox;
